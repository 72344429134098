import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  CssBaseline,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import React, { useEffect } from "react";
import Footer from "./footer";
import { Link as RouterLink } from "react-router-dom";
import { deepPurple } from "@mui/material/colors";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Helmet } from "react-helmet-async";

const himanchaldata = [
  {
    id: 1,
    img: "https://www.travelmurti.com/himac/Chaomli.jpg",
    name: "Shimla Short Escape Tour Package",
    duration: "2 Nights & 3 Days",
    price: "10,999",
    label: "shimlashortescapetourpackage",
  },
  {
    id: 2,
    img: "https://www.travelmurti.com/himac/Manali2.jpg",
    name: "Manali Tour Package",
    duration: "2 Nights & 3 Days",
    price: "6,999",
    label: "manalitourpackage",
  },
  {
    id: 3,
    img: "https://reactmapmisha.shiftescape.com/images/TravelMurti/himachal/3/himachal10.jpg",
    name: "Manali Tour Package Ex - Delhi",
    duration: "3 Nights & 4 Days",
    price: "9,999",
    label: "manalitourpackageex-delhi",
  },
  {
    id: 4,
    img: "https://www.travelmurti.com/himac/shimla3.jpg",
    name: "Manali Tour Ex - Chandigarh",
    duration: "3 Nights & 4 Days",
    price: "6,999",
    label: "manalitourpackageex-chandigarh",
  },
  {
    id: 5,
    img: "https://www.travelmurti.com/himac/shimla6.jpg",
    name: "Silver Triangle Tour",
    duration: "6 Nights & 7 Days",
    price: "16,999",
    label: "slivertriangletour",
  },
  {
    id: 6,
    img: "https://www.travelmurti.com/himac/shimla5.jpg",
    name: "Himachal Highlight With Kasauli",
    duration: "6 Nights & 7 Days",
    price: "14,999",
    label: "himachalhighlightwithkasauli",
  },
];
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(deepPurple[900]),
  backgroundColor: deepPurple[900],
  "&:hover": {
    backgroundColor: deepPurple[400],
    color: "white",
  },
}));

export default function HimachalKashmir() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Travel Murti | Himanchal Tour</title>
        <meta
          name="description"
          content="Himachal Place is one of the most adorable tourism places of India.
          Himachal is mostly preferred by Youngsters or by newly married
          couples. A complete tour package of Himachal consists of several
          visits."
        ></meta>
      </Helmet>
      <Box className="himanchal-package" sx={{ height: 190 }}>
        <Typography
          component="h3"
          variant="h4"
          align="center"
          color="#fff"
          fontWeight="bold"
          style={{
            padding: "30px 0",
            position: "relative",
            textTransform: "uppercase",
            margin: "0",
          }}
        >
          Himachal Tour Package
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="#fff"
          component="p"
          fontWeight="bold"
          sx={{
            p: 1,
          }}
        >
          Home <ChevronRightIcon /> Himachal Tour Package
        </Typography>
      </Box>

      <CssBaseline />
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          component="h2"
          sx={{ p: 3, textAlign: "center", fontWeight: "bold" }}
        >
          Himachal Tour Package
        </Typography>
        <Typography sx={{ p: 1, textAlign: "center", color: "#636a8c" }}>
          Himachal Place is one of the most adorable tourism places of India.
          Himachal is mostly preferred by Youngsters or by newly married
          couples. A complete tour package of Himachal consists of several
          visits. Pleasant weather can be enjoyed in the morning and if you
          belong to the deserted city then you will definitely fall in love with
          the afternoons’ of Manali. You will feel that you should have a home
          out there in Manali but y friend you are on a trip so you have to move
          ahead. Next destination would be Dharamshala where you will feel
          relaxed in the green fields. The last target would be Dalhousie and it
          will give make you feel special.
        </Typography>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ mt: 10 }}
          >
            {himanchaldata.map((item, index) => (
              <Grid item xs={4} sm={4} md={4} key={index}>
                <div className="gradient-border">
                  <Card
                    sx={{
                      bgcolor: "#fff",
                      borderRadius: "5px",
                      flexGrow: 1,
                      boxShadow: "0 2px 40px 8px rgb(15 15 15 / 15%)",
                      zIndex: 1,
                    }}
                  >
                    <CardMedia
                      sx={{ height: 200 }}
                      image={item.img}
                      title="green iguana"
                    />
                    <CardContent>
                      <Typography
                        sx={{ textAlign: "center" }}
                        gutterBottom
                        variant="h5"
                        component="div"
                        color="darkviolet"
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center" }}
                        variant="h6"
                        color="text.secondary"
                      >
                        Duration: {item.duration}
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center" }}
                        variant="h5"
                        color="text.secondary"
                      >
                        <CurrencyRupeeIcon />{" "}
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          {item.price}
                        </span>{" "}
                        per person
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "center" }}>
                      <ColorButton
                        component={RouterLink}
                        to={`/himachaltourpackage/${item.label}`}
                        variant="contained"
                      >
                        View Details
                      </ColorButton>
                    </CardActions>
                  </Card>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
