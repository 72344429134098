import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  CssBaseline,
  Grid,
  Typography,
} from "@mui/material";
import Footer from "../footer";
import CallIcon from "@mui/icons-material/Call";
import * as React from "react";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import data from "./data.json";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { Helmet } from "react-helmet-async";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px;
  margin: 6px 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  min-width: 400px;
  background-color: ${blue[500]};
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 8px ${
    theme.palette.mode === "dark" ? grey[900] : grey[200]
  };
  `
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function ChardhamPackage() {
  const { label } = useParams();

  const [item, setData] = React.useState({});

  React.useEffect(() => {
    setData(data.find((item) => item.label === label));
  }, [label]);

  //const item = data.find((item) => item.id === id);

  console.log(item);

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 4;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // eslint-disable-next-line
  const [expanded, setExpanded] = React.useState(false);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>{item.title}</title>
        <meta
          name="description"
          content="As one of the spiritual tour, Chardham Yatra carries a lot of
          devotion. This holy expedition of sacred places in India includes the
          visit of four shrines, namely Yamunotri, Gangotri, Kedarnath and
          Badrinath Dham. Visiting these four sacred places together is called
          Chota Char Dham Yatra, which is located at the Himalayan foothills of
          Uttarakhand."
        ></meta>
      </Helmet>
      <Box className="background-image" sx={{ height: 175 }}>
        <Typography
          component="div"
          variant="h4"
          align="center"
          color="#fff"
          fontWeight={500}
          style={{
            padding: "60px 0",
            position: "relative",
            textTransform: "uppercase",
            margin: "0",
          }}
        >
          {item?.title}
        </Typography>
      </Box>
      <CssBaseline />
      <Container maxWidth="lg">
        <Box sx={{ flexGrow: 1, mt: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={8} sx={{ p: 1 }}>
              {/* <Paper
                square
                elevation={0}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: 50,
                  pl: 2,
                  bgcolor: "background.default",
                }}
              >
                <Typography>{item.images[activeStep].label}</Typography>
              </Paper> */}
              <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {item.images?.map((step, index) => (
                  <div key={step.label}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        component="img"
                        sx={{
                          height: 450,
                          display: "block",
                          overflow: "hidden",
                          width: "100%",
                        }}
                        src={step.imgPath}
                        alt={step.label}
                      />
                    ) : null}
                  </div>
                ))}
              </AutoPlaySwipeableViews>
              <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    Next
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              style={{ border: "4px solid #ececec" }}
            >
              <Typography
                component="p"
                variant="h5"
                fontWeight={500}
                padding={1}
              >
                Help & Support ?
              </Typography>
              <Typography component="p" padding={1}>
                For Tour Packages, Vehicle Rental and Customer Care Support.
              </Typography>
              <Box>
                <CallIcon
                  style={{
                    color: "#1cc3b2",
                    fontSize: "30px",
                  }}
                />
                <span
                  style={{
                    fontSize: "25px",
                    fontWeight: "500",
                    color: "#1cc3b2",
                    padding: 3,
                  }}
                >
                  +91 085270 36496
                </span>
                <Typography
                  component="p"
                  padding={1}
                  color="#636a76"
                  fontSize="20px"
                  fontWeight={400}
                >
                  contact@travelmurti.com
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* Tabs code start here */}
        <Box sx={{ width: "100%" }}>
          <TabsUnstyled defaultValue={0}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabsList
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="scrollable auto tabs example"
              >
                <Tab label="INTRODUCTION" {...a11yProps(0)}>
                  INTRODUCTION
                </Tab>
                <Tab label="ITINERARY" {...a11yProps(1)}>
                  ITINERARY
                </Tab>
                <Tab label="INCLUDE/EXCLUDE" {...a11yProps(2)}>
                  INCLUDE/EXCLUDE
                </Tab>
              </TabsList>
            </Box>

            <TabPanel value={0} index={0}>
              {item.introduction?.map((item, index) => (
                <Accordion key={index} expanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{item.desc}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </TabPanel>
            <TabPanel value={1} index={1}>
              {item.itinerary?.map((plan, index) => (
                <Accordion
                  key={index}
                  expanded
                  //expanded={expanded === index}
                  onChange={handleChangeAccordion(index)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {plan.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{plan.description}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </TabPanel>
            <TabPanel value={2} index={2}>
              <Typography sx={{ fontWeight: "bold" }}>
                PACKAGE INCLUDE:-
              </Typography>
              <ul>
                {item.include?.map((item, index) => (
                  <li key={index}>
                    <Typography variant="subtitle1">
                      {item.includelist}
                    </Typography>
                  </li>
                ))}
              </ul>
              <Typography sx={{ fontWeight: "bold" }}>
                PACKAGE EXCLUDE:-
              </Typography>
              <ul>
                {item.exclude?.map((item, index) => (
                  <li key={index}>
                    <Typography variant="subtitle1">
                      {item.excludelist}
                    </Typography>
                  </li>
                ))}
              </ul>
            </TabPanel>
          </TabsUnstyled>
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
