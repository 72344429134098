import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import AuthService from "../services/auth.service";
import { useEffect } from "react";
import moment from "moment/moment";

const columns = [
  { field: "id", headerName: "ID", width: 250 },
  {
    field: "createdAt",
    headerName: "Date",
    width: 250,
    valueFormatter: (params) => moment(params.value).format("YYYY-MM-DD"),
  },
  { field: "name", headerName: "First name", width: 250 },
  { field: "mobile", headerName: "Mobile Number", width: 250 },
  {
    field: "email",
    headerName: "Email",
    width: 250,
  },
];

export default function DataTable() {
  const effectRan = React.useRef(false);
  const [users, setUser] = React.useState([]);

  useEffect(() => {
    if (effectRan.current === false) {
      retrieveUser();
      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  const retrieveUser = (e) => {
    AuthService.getAll()
      .then((response) => {
        setUser(response.data);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div style={{ height: 520, width: "100%" }}>
      <DataGrid
        rows={users}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20]}
      />
    </div>
  );
}
