import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  CssBaseline,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import React, { useEffect } from "react";
import Footer from "./footer";
import { Link as RouterLink } from "react-router-dom";
import { deepPurple } from "@mui/material/colors";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Helmet } from "react-helmet-async";

const chardhamdata = [
  {
    id: 1,
    label: "chardhamtourexharidwar",
    img: "https://www.travelmurti.com/chardhamimages/chardham6.jpg",
    name: "Chardham Tour Package Ex Haridwar",
    duration: "9 Nights & 10 Days",
    price: "22,999",
  },
  {
    id: 2,
    label: "chardhamhelicoptertour",
    img: "https://www.travelmurti.com/chardhamimages/chardhamhelicopter.jpg",
    name: "Chardham Helicopter Tour Package",
    duration: "4 Nights & 5 Days",
    price: "1,85,000",
  },
  {
    id: 3,
    label: "chardhamtourexdelhi",
    img: "https://www.travelmurti.com/chardhamimages/chardham1.jpg",
    name: "Chardham Tour Package Ex Delhi",
    duration: "10 Nights & 11 Days",
    price: "29,999",
  },
  {
    id: 4,
    label: "chardhamtourwithhemkundsahib",
    img: "https://www.travelmurti.com/chardhamimages/Hemkund%20Sahib%203.jpg",
    name: "Chardham Tour with Hemkund Sahib",
    duration: "11 Nights & 12 Days",
    price: "25,999",
  },
  {
    id: 5,
    label: "teendhamtourpackage",
    img: "https://www.travelmurti.com/chardhamimages/teen%20dham.jpg",
    name: "Teen Dham Tour Package",
    duration: "7 Nights & 8 Days",
    price: "20,999",
  },
  {
    id: 6,
    label: "dodhamtourpackage",
    img: "https://www.travelmurti.com/chardhamimages/DoDham1.jpg",
    name: "Do Dham Tour Package",
    duration: "7 Nights & 8 Days",
    price: "18,999",
  },
  {
    id: 7,
    label: "kedarnathtourpackage",
    img: "https://www.travelmurti.com/chardhamimages/kedarnath1.jpg",
    name: "Kedarnath Tour Package Ex Haridwar",
    duration: "3 Nights & 4 Days",
    price: "10,999",
  },
  {
    id: 8,
    label: "badrinathtourpackage",
    img: "https://www.travelmurti.com/chardhamimages/badrinath.jpg",
    name: "Badrinath Tour Ex Haridwar",
    duration: "3 Nights & 4 Days",
    price: "10,999",
  },
];

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(deepPurple[900]),
  backgroundColor: deepPurple[900],
  "&:hover": {
    backgroundColor: deepPurple[400],
    color: "white",
  },
}));
export default function ChardhamYatra() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Travel Murti | Chardham Tour</title>
        <meta
          name="description"
          content="As one of the spiritual tour, Chardham Yatra carries a lot of
          devotion. This holy expedition of sacred places in India includes the
          visit of four shrines, namely Yamunotri, Gangotri, Kedarnath and
          Badrinath Dham. Visiting these four sacred places together is called
          Chota Char Dham Yatra, which is located at the Himalayan foothills of
          Uttarakhand."
        ></meta>
      </Helmet>
      <Box className="chardham-package bg-overlay" sx={{ height: 190 }}>
        <Typography
          component="h3"
          variant="h4"
          align="center"
          color="#fff"
          fontWeight="bold"
          style={{
            padding: "30px 0",
            position: "relative",
            textTransform: "uppercase",
            margin: "0",
          }}
        >
          Chardham Tour Package
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="#fff"
          component="p"
          fontWeight="bold"
          sx={{
            p: 1,
          }}
        >
          Home <ChevronRightIcon /> Chardham Tour Package
        </Typography>
      </Box>

      <CssBaseline />
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          component="h2"
          sx={{ p: 3, textAlign: "center", fontWeight: "bold" }}
        >
          Chardham Tour Package
        </Typography>
        <Typography sx={{ p: 1, textAlign: "center", color: "#636a8c" }}>
          As one of the spiritual tour, Chardham Yatra carries a lot of
          devotion. This holy expedition of sacred places in India includes the
          visit of four shrines, namely Yamunotri, Gangotri, Kedarnath and
          Badrinath Dham. Visiting these four sacred places together is called
          Chota Char Dham Yatra, which is located at the Himalayan foothills of
          Uttarakhand. The char dham tour begins from Haridwar, which is the
          perfect gateway to reach Uttarkashi for Yamunotri and Gangotri &
          Kedarnath shrine, followed by a visit to Badrinath temple.
        </Typography>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ mt: 10 }}
          >
            {chardhamdata.map((item, index) => (
              <Grid item xs={4} sm={4} md={4} key={index}>
                <div className="gradient-border">
                  <Card
                    sx={{
                      bgcolor: "#fff",
                      borderRadius: "5px",
                      flexGrow: 1,
                      boxShadow: "0 2px 40px 8px rgb(15 15 15 / 15%)",
                      zIndex: 1,
                    }}
                  >
                    <CardMedia
                      sx={{ height: 200 }}
                      image={item.img}
                      title="green iguana"
                    />
                    <CardContent>
                      <Typography
                        sx={{ textAlign: "center" }}
                        gutterBottom
                        variant="h5"
                        component="div"
                        color="blue"
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center" }}
                        variant="h6"
                        color="text.secondary"
                      >
                        Duration: {item.duration}
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center" }}
                        variant="h5"
                        color="text.secondary"
                      >
                        <CurrencyRupeeIcon />
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          {item.price}
                        </span>{" "}
                        per person
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "center" }}>
                      <ColorButton
                        component={RouterLink}
                        to={`/chardhamtourpackage/${item.label}`}
                        variant="contained"
                      >
                        View Details
                      </ColorButton>
                    </CardActions>
                  </Card>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
