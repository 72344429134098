import { Box, Container, CssBaseline, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Footer from "./footer";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Helmet } from "react-helmet-async";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Travel Murti | About Us</title>
        <meta
          name="description"
          content="Travel Murti is a one-stop enterprise that offers the complete range of travel
            related services. Superior knowledge, efficient planning and the
            ability to anticipate & resolve potential problems along the way are
            the reason behind our success. We make your travel economical, safe,
            fun, informative, comfortable & memorable With young Indian
            Destination Management Professionals, our vision is to offer
            reliable contribution towards the growth of travel and tourism
            industry."
        ></meta>
      </Helmet>
      <Box className="background-image" sx={{ height: 160 }}>
        <Typography
          component="h3"
          variant="h3"
          align="center"
          color="#fff"
          fontWeight={400}
          style={{
            padding: "20px 0",
            position: "relative",
            textTransform: "uppercase",
            margin: "0",
          }}
        >
          About Us
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="#fff"
          component="p"
          fontWeight="bold"
          sx={{
            p: 1,
          }}
        >
          Home <ChevronRightIcon /> About Us
        </Typography>
      </Box>
      <CssBaseline />
      <Container maxWidth="lg">
        <Box
          sx={{
            mt: 8,
            p: 2,
          }}
        >
          <Typography>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                p: 2,
                color: "#5A5A5A",
              }}
            >
              Travel Murti{" "}
            </span>
            is a one-stop enterprise that offers the complete range of travel
            related services. Superior knowledge, efficient planning and the
            ability to anticipate & resolve potential problems along the way are
            the reason behind our success. We make your travel economical, safe,
            fun, informative, comfortable & memorable With young Indian
            Destination Management Professionals, our vision is to offer
            reliable contribution towards the growth of travel and tourism
            industry. We feel obliged to introduce our ambitious venture “Travel
            Murti” as an entity which takes care of any tour & travel
            requirements of its estimated time at global level.
          </Typography>
          <Typography
            sx={{
              mt: 5,
            }}
          >
            Travel Murti Pvt. Ltd. has ventured to meet the requirements of
            individual as well the group traveler for leisure or business. We
            are capable of operating tours in any Destination of India, when put
            together we are a One-Stop Shop for all types of travel
            requirements.
          </Typography>
          <Typography
            sx={{
              mt: 5,
            }}
          >
            From very beginning we are committed to our best of services to the
            valued customers and socialized in tailored made tour program to
            suit your time and budget.
          </Typography>
          <Typography
            sx={{
              mt: 5,
            }}
          >
            As a company we are committed to support our clients objective of
            providing world class Travel services at an economical price.
          </Typography>

          <Typography
            style={{
              fontWeight: "500",
              fontSize: "22px",
              color: "#5A5A5A",
              marginTop: "20px",
            }}
          >
            Our Strengths
          </Typography>

          <Typography
            sx={{
              mt: 5,
            }}
          >
            We value our longstanding relationships with our customers. You can
            be assured of the best information on destinations and the most
            competitive pricing.
          </Typography>

          <Typography
            sx={{
              mt: 5,
            }}
          >
            We have our associate offices and representatives all over India,
            which gives us an added advantage to provide, customized and
            personalized services to our clients.
          </Typography>
          <Typography
            sx={{
              mt: 5,
            }}
          >
            Attention to every requirement of our clients and partners. We pride
            ourselves in giving attention to all details.customized and
            personalized services to our clients.
          </Typography>

          <Typography
            style={{
              fontWeight: "500",
              fontSize: "22px",
              color: "#5A5A5A",
              marginTop: "20px",
            }}
          >
            Commitment
          </Typography>
          <Typography
            sx={{
              mt: 5,
            }}
          >
            Travel Murti Pvt. Ltd. offers a full range of services, from
            planning your travel, arranging the ground operations including
            special arrangements with a high level of Customer services anywhere
            in India.
          </Typography>
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
