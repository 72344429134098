import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  CssBaseline,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import React, { useEffect } from "react";
import Footer from "./footer";
import { Link as RouterLink } from "react-router-dom";
import { deepPurple } from "@mui/material/colors";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Helmet } from "react-helmet-async";

const kashmirdata = [
  {
    id: 1,
    img: "https://reactmapmisha.shiftescape.com/images/TravelMurti/Kashmir/1/kashmir1.jpg",
    name: "Kashmir Trip with Gulmarg",
    duration: "3 Nights & 4 Days",
    price: "7,999",
    label: "kashmirtripwithgulmarg",
  },
  {
    id: 2,
    img: "https://reactmapmisha.shiftescape.com/images/TravelMurti/Kashmir/2/kashmir5.jpg",
    name: "Kashmir Tour with Gulmarg & Pahalgam",
    duration: "4 Nights & 5 Days",
    price: "13,999",
    label: "kashmirtripwithgulmarg&pahalgam",
  },
  {
    id: 3,
    img: "https://reactmapmisha.shiftescape.com/images/TravelMurti/Kashmir/3/kashmir9.jpg",
    name: "Kashmir Bliss Tour Package",
    duration: "4 Nights & 5 Days",
    price: "10,999",
    label: "kashmirblisstour",
  },
  {
    id: 4,
    img: "https://reactmapmisha.shiftescape.com/images/TravelMurti/Kashmir/4/kashmir13.jpg",
    name: "Explore Kashmir Tour",
    duration: "5 Nights & 6 Days",
    price: "15,999",
    label: "exolorekashmirtour",
  },
  {
    id: 5,
    img: "https://reactmapmisha.shiftescape.com/images/TravelMurti/Kashmir/5/kashmir17.jpg",
    name: "Kashmir Tour Ex - Jammu",
    duration: "5 Nights & 6 Days",
    price: "19,999",
    label: "kashmirtourex-jammu",
  },
  {
    id: 6,
    img: "https://reactmapmisha.shiftescape.com/images/TravelMurti/Kashmir/6/kashmir21.jpg",
    name: "Kashmir Tour Package Ex - Katra",
    duration: "5 Nights & 6 Days",
    price: "20,999",
    label: "kashmirtourex-katra",
  },
];

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(deepPurple[900]),
  backgroundColor: deepPurple[900],
  "&:hover": {
    backgroundColor: deepPurple[400],
    color: "white",
  },
}));

export default function Kashimar() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Travel Murti | Kashmir Tour</title>
        <meta
          name="description"
          content="Jammu and Kashmir, the jewel in the crown of India, is one of the most
          gorgeous and highest visited tourist destinations in the country.
          Among all the different places to visit in this magnificent state, its
          capital city or Srinagar is considered as the epicentre of Kashmir
          tourism. Over the 7 days of this exciting tour to Srinagar, you will
          get to visit several scenic hill stations, mountain peaks, enticing
          rivers and lot more. Along with these, you will also get an
          opportunity to enjoy Shikara rides in the gorgeous Dal Lake, pony
          rides and can also participate in an enthralling trek. While in
          Srinagar, you will also get to visit some of its most popular and
          not-to-be-missed landmarks."
        ></meta>
      </Helmet>
      <Box className="kashmir-package" sx={{ height: 190 }}>
        <Typography
          component="h3"
          variant="h4"
          align="center"
          color="#fff"
          fontWeight="bold"
          style={{
            padding: "30px 0",
            position: "relative",
            textTransform: "uppercase",
            margin: "0",
          }}
        >
          Kashmir Tour Package
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="#fff"
          component="p"
          fontWeight="bold"
          sx={{
            p: 1,
          }}
        >
          Home <ChevronRightIcon /> Kashmir Tour Package
        </Typography>
      </Box>

      <CssBaseline />
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          component="h2"
          sx={{ p: 3, textAlign: "center", fontWeight: "bold" }}
        >
          Kashmir Tour Package
        </Typography>
        <Typography sx={{ p: 1, textAlign: "center", color: "#636a8c" }}>
          Jammu and Kashmir, the jewel in the crown of India, is one of the most
          gorgeous and highest visited tourist destinations in the country.
          Among all the different places to visit in this magnificent state, its
          capital city or Srinagar is considered as the epicentre of Kashmir
          tourism. Over the 7 days of this exciting tour to Srinagar, you will
          get to visit several scenic hill stations, mountain peaks, enticing
          rivers and lot more. Along with these, you will also get an
          opportunity to enjoy Shikara rides in the gorgeous Dal Lake, pony
          rides and can also participate in an enthralling trek. While in
          Srinagar, you will also get to visit some of its most popular and
          not-to-be-missed landmarks.
        </Typography>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ mt: 10 }}
          >
            {kashmirdata.map((item, index) => (
              <Grid item xs={4} sm={4} md={4} key={index}>
                <div className="gradient-border">
                  <Card
                    sx={{
                      bgcolor: "#fff",
                      borderRadius: "5px",
                      flexGrow: 1,
                      boxShadow: "0 2px 40px 8px rgb(15 15 15 / 15%)",
                      zIndex: 1,
                    }}
                  >
                    <CardMedia
                      sx={{ height: 200 }}
                      image={item.img}
                      title="green iguana"
                    />
                    <CardContent>
                      <Typography
                        sx={{ textAlign: "center" }}
                        gutterBottom
                        variant="h5"
                        component="div"
                        color="deeppink"
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center" }}
                        variant="h6"
                        color="text.secondary"
                      >
                        Duration: {item.duration}
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center" }}
                        variant="h5"
                        color="text.secondary"
                      >
                        <CurrencyRupeeIcon />{" "}
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          {item.price}
                        </span>{" "}
                        per person
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "center" }}>
                      <ColorButton
                        component={RouterLink}
                        to={`/kashmirtourpackage/${item.label}`}
                        variant="contained"
                      >
                        View Details
                      </ColorButton>
                    </CardActions>
                  </Card>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
