import "bootstrap/dist/css/bootstrap.min.css";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./error-page";
import Root from "./routes/root";
import ChardhamYatra from "./routes/chardham";
import Gujrat from "./routes/gujrat";
import HimachalKashmir from "./routes/himachal";
import Home from "./routes/home";
import About from "./routes/about";
import Contact from "./routes/contactus";
import ChardhamPackage from "./routes/pages/chardhampackage";
import GujratPackage from "./routes/pages/gujratpackage";
import Kashimar from "./routes/kashmir";
import KashmirPackage from "./routes/pages/kashmirpackage";
import HandKPackage from "./routes/pages/handkpackage";
import Page404 from "./Page404";
import { HelmetProvider } from "react-helmet-async";
import { hydrate, render } from "react-dom";
import DataTable from "./routes/pages/UserList";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "chardhamtourpackage",
        element: <ChardhamYatra />,
      },
      {
        path: "gujrattourpackage",
        element: <Gujrat />,
      },
      {
        path: "himachaltourpackage",
        element: <HimachalKashmir />,
      },
      {
        path: "kashmirtourpackage",
        element: <Kashimar />,
      },
      {
        path: "contactus",
        element: <Contact />,
      },
      {
        path: "/chardhamtourpackage/:label",
        element: <ChardhamPackage/>,
      },
      {
        path: "/gujrattourpackage/:label",
        element: <GujratPackage/>,
      },
      {
        path: "/himachaltourpackage/:label",
        element: <HandKPackage/>,
      },
      {
        path: "/kashmirtourpackage/:label",
        element: <KashmirPackage/>,
      },
      {
        path: "/user/list/travelmurti",
        element: <DataTable/>,
      },
     
    ],
  },
  { path: '*', element: <Page404 /> },
]);

const rootElement = ReactDOM.createRoot(document.getElementById("root"));

rootElement.render(
  <React.StrictMode>
     <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </React.StrictMode>
);

if (rootElement.hasChildNodes()) {
  hydrate(<Root />, rootElement);
} else {
  render(<Root />, rootElement);
}