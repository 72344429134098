import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Rating,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import InfoIcon from "@mui/icons-material/Info";
import Footer from "./footer";
import { NavLink as RouterLink } from "react-router-dom";
import { cyan, lightBlue } from "@mui/material/colors";
import { Helmet } from "react-helmet-async";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import StarIcon from "@mui/icons-material/Star";
import ContactForm from "./Contact";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(lightBlue[700]),
  backgroundColor: cyan[700],
  "&:hover": {
    backgroundColor: cyan[400],
    color: "white",
  },
}));

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

const allpackages = [
  {
    id: 1,
    img: "https://www.travelmurti.com/chardhamimages/chardham6.jpg",
    name: "Chardham Tour Package",
    duration: "9 Nights & 10 Days",
    price: "23,999",
    path: "chardhamtourpackage",
  },
  {
    id: 2,
    img: "https://www.travelmurti.com/gujaratimages/somnathtemple.jpg",
    name: "Gujrat Tour Package",
    duration: "5 Nights & 6 Days",
    price: "16,999",
    path: "gujrattourpackage",
  },
  {
    id: 3,
    img: "https://i.pinimg.com/564x/3b/4f/28/3b4f288a6b0891676cf303c4f564829a.jpg",
    name: "Himachal Tour Package",
    duration: "3 Nights & 5 Days",
    price: "9,999",
    path: "himachaltourpackage",
  },
  {
    id: 4,
    img: "https://www.travelmurti.com/chardhamimages/cfc5f3ecf096c97033a3746917966395.jpg",
    name: "Kashmir Tour Package",
    duration: "3 Nights & 5 Days",
    price: "9,999",
    path: "kashmirtourpackage",
  },
];

const hotpackages1 = [
  {
    id: 1,
    img: "https://www.travelmurti.com/chardhamimages/chardhamhelicopter.jpg",
    name: "Chardham Tour Package",
    duration: "5 Nights & 6 Days",
    price: "15,999",
    path: "chardhamtourpackage",
  },
  {
    id: 2,
    img: "https://reactmapmisha.shiftescape.com/images/TravelMurti/gujrat/1/gujrat3.jpg",
    name: "Gujrat Tour Package",
    duration: "5 Nights & 6 Days",
    price: "16,999",
    path: "gujrattourpackage",
  },
  {
    id: 3,
    img: "https://reactmapmisha.shiftescape.com/images/TravelMurti/himachal/1/manali2.jpg",
    name: "Himachal Tour Package",
    duration: "3 Nights & 5 Days",
    price: "5,999",
    path: "himachaltourpackage",
  },
  {
    id: 4,
    img: "https://reactmapmisha.shiftescape.com/images/TravelMurti/Kashmir/1/kashmir2.jpg",
    name: "Kashimar Tour Package",
    duration: "3 Nights & 5 Days",
    price: "9,999",
    path: "kashmirtourpackage",
  },
];

const hotpackages2 = [
  {
    id: 1,
    img: "https://www.travelmurti.com/chardhamimages/DoDham1.jpg",
    name: "Chardham Tour Package",
    duration: "9 Nights & 10 Days",
    price: "23,999",
    path: "chardhamtourpackage",
  },
  {
    id: 2,
    img: "https://i.pinimg.com/564x/ad/f1/ed/adf1ed92de17475ba8bc047ea3721b2c.jpg",
    name: "Gujrat Tour Package",
    duration: "5 Nights & 6 Days",
    price: "16,999",
    path: "gujrattourpackage",
  },
  {
    id: 3,
    img: "https://i.pinimg.com/564x/61/68/19/616819624aa06606c5be421d4feab390.jpg",
    name: "Himachal Tour Package",
    duration: "3 Nights & 5 Days",
    price: "5,999",
    path: "himachaltourpackage",
  },
  {
    id: 4,
    img: "https://i.pinimg.com/564x/6b/38/b6/6b38b631b40c8944d9257be9d0e2d141.jpg",
    name: "Kashimar Tour Package",
    duration: "3 Nights & 5 Days",
    price: "9,999",
    path: "kashmirtourpackage",
  },
];

const images = [
  {
    url: "https://image3.mouthshut.com/images/imagesp/925752570s.jpg",
    title: "Akshardham",
    width: "40%",
  },
  {
    url: "https://thumbs.dreamstime.com/b/hawa-mahal-palace-jaipur-rajasthan-winds-36197345.jpg",
    title: "Hawa Mahal",
    width: "30%",
  },
  {
    url: "https://www.tourmyindia.com/blog//wp-content/uploads/2018/06/Popular-Temples-in-Jaipur-Birla-Temple.jpg",
    title: "Birla Temple",
    width: "30%",
  },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 100,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "4px solid currentColor",
    },
  },
}));

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
}));

const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity"),
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const value = 3.5;
  return (
    <>
      <Helmet>
        <title>Travel Murti</title>
        <meta
          name="description"
          content="Travel Murti is a one-stop enterprise that offers the complete range of travel related services. Superior knowledge, efficient planning and the ability to anticipate & resolve potential problems along the way are the reason behind our success. We make your travel economical, safe, fun, informative, comfortable & memorable With young Indian Destination Management Professionals, our vision is to offer reliable contribution towards the growth of travel and tourism industry. We feel obliged to introduce our ambitious venture “Travel Murti” as an entity which takes care of any tour & travel requirements of its estimated time at global level."
        ></meta>
        <meta
          name="keyword"
          content="Travel Murti, travel murti, chardham tour, chardham yatra, gujarat tour, spiritual tour, himanchal tour, kashmir tour, himanchal tour package, kashmir tour package, best tour places"
        ></meta>
      </Helmet>
      <Box>
        <Carousel activeIndex={index} onSelect={handleSelect} fade>
          <Carousel.Item>
            <img
              className="d-block welcome-slide w-100"
              src="https://www.akshartours.com/wp-content/uploads/2022/03/Chardhamyatra-akshar.jpg"
              alt="Chardham Yatra Pilgrimage & Religious Tours"
            />
            <Carousel.Caption>
              <Box
                sx={{
                  backgroundColor: "rgba(8, 17, 10, 0.5)",
                  padding: 1,
                }}
              >
                <h3>Chardham Yatra Pilgrimage & Religious Tours</h3>
                <p style={{ fontWeight: "bold" }}>
                  Sacred Visit to Badrinath, Kedarnath, Gangotri & Yamunotri.
                </p>
              </Box>

              <ImageButton
                focusRipple
                style={{
                  width: "20%",
                }}
                component={RouterLink}
                to={"/chardhamtourpackage"}
              >
                <ImageBackdrop className="MuiImageBackdrop-root" />
                <Image>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    sx={{
                      position: "relative",
                      p: 4,
                      pt: 2,
                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                      border: "4px solid",
                    }}
                  >
                    <span>Read More...</span>
                    <ImageMarked className="MuiImageMarked-root" />
                  </Typography>
                </Image>
              </ImageButton>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block welcome-slide w-100"
              src="https://akshardham.com/gujarat/wp-content/uploads/2017/04/slider1.jpg"
              alt="Gujarat Tours Packages"
            />

            <Carousel.Caption>
              <Box
                sx={{
                  backgroundColor: "rgba(8, 17, 10, 0.5)",
                  padding: 1,
                }}
              >
                <h3>Gujarat Tours Packages</h3>
                <p style={{ fontWeight: "bold" }}>
                  In Gujarat all temples are very famous for their worship and
                  pilgrims.
                </p>
              </Box>
              <ImageButton
                focusRipple
                style={{
                  width: "20%",
                }}
                component={RouterLink}
                to={"/gujrattourpackage"}
              >
                <ImageBackdrop className="MuiImageBackdrop-root" />
                <Image>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    sx={{
                      position: "relative",
                      p: 4,
                      pt: 2,
                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                      border: "4px solid",
                    }}
                  >
                    <span>Read More...</span>
                    <ImageMarked className="MuiImageMarked-root" />
                  </Typography>
                </Image>
              </ImageButton>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block welcome-slide w-100"
              src="https://images.pexels.com/photos/161293/prambanan-temple-java-hinduism-161293.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="Best Holiday & Adventure Trips"
            />

            <Carousel.Caption>
              <Box
                sx={{
                  backgroundColor: "rgba(8, 17, 10, 0.5)",
                  padding: 1,
                }}
              >
                <h3>Best Holiday & Adventure Trips</h3>
                <p style={{ fontWeight: "bold" }}>
                  Shimla, Kullu-Manali, Rajasthan, Goa, Kashmir,Bangalore &
                  Ooty.
                </p>
              </Box>
              <ImageButton
                focusRipple
                style={{
                  width: "20%",
                }}
                component={RouterLink}
                to={"/kashmirtourpackage"}
              >
                <ImageBackdrop className="MuiImageBackdrop-root" />
                <Image>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    sx={{
                      position: "relative",
                      p: 4,
                      pt: 2,
                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                      border: "4px solid",
                    }}
                  >
                    <span>Read More...</span>
                    <ImageMarked className="MuiImageMarked-root" />
                  </Typography>
                </Image>
              </ImageButton>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block welcome-slide w-100"
              src="https://images.tv9hindi.com/wp-content/uploads/2022/06/Jagannath-mandir-mystery-kitchen-prasad-temple-flag-wave-opposite-air-ocean-and-Nilachakra-5.jpg"
              alt="Jagannath Puri"
            />

            <Carousel.Caption>
              <Box
                sx={{
                  backgroundColor: "rgba(8, 17, 10, 0.5)",
                  padding: 1,
                }}
              >
                <h3>Jagannath Puri Packages</h3>
                <p style={{ fontWeight: "bold" }}>
                  In Jagannath all temples are very famous for their worship and
                  pilgrims.
                </p>
              </Box>
              <ImageButton
                focusRipple
                style={{
                  width: "20%",
                }}
              >
                <ImageBackdrop className="MuiImageBackdrop-root" />
                <Image>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    sx={{
                      position: "relative",
                      p: 4,
                      pt: 2,
                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                      border: "4px solid",
                    }}
                  >
                    <span>Read More...</span>
                    <ImageMarked className="MuiImageMarked-root" />
                  </Typography>
                </Image>
              </ImageButton>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block welcome-slide w-100"
              src="https://blogs.devdarshanapp.com/wp-content/uploads/2021/12/tirupati-balaji-temple.jpg-770x463.jpg"
              alt="Tirupati Balaji"
            />

            <Carousel.Caption>
              <Box
                sx={{
                  backgroundColor: "rgba(8, 17, 10, 0.5)",
                  padding: 1,
                }}
              >
                <h3>Tirupati Balaji Tours Packages</h3>
                <p style={{ fontWeight: "bold" }}>
                  In Tirupati all temples are very famous for their worship and
                  pilgrims.
                </p>
              </Box>
              <ImageButton
                focusRipple
                style={{
                  width: "20%",
                }}
              >
                <ImageBackdrop className="MuiImageBackdrop-root" />
                <Image>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    sx={{
                      position: "relative",
                      p: 4,
                      pt: 2,
                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                      border: "4px solid",
                    }}
                  >
                    <span>Read More...</span>
                    <ImageMarked className="MuiImageMarked-root" />
                  </Typography>
                </Image>
              </ImageButton>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <CssBaseline />
        <Container maxWidth="lg">
          <Typography
            variant="h5"
            component="h2"
            sx={{ mt: 3, textAlign: "center", fontWeight: "bold" }}
          >
            Trending Tour Pakcage
          </Typography>
          <Typography
            sx={{
              mt: 1,
              mb: 1,
              textAlign: "center",
              fontWeight: "500",
              color: "#5a6268",
            }}
          >
            Check out these amazing devotional trips
          </Typography>
          <hr className="hrborder" />
          <Box sx={{ flexGrow: 1 }}></Box>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {allpackages.map((item, index) => (
              <Grid item xs={12} sm={3} md={3} key={index}>
                <div className="gradient-border">
                  <Card
                    sx={{
                      borderRadius: "4px",
                      "&:hover": {
                        bgcolor: "#fff",
                        borderRadius: "4px",
                        flexGrow: 1,
                        boxShadow: "-1px 10px 29px 0px rgba(0,0,0,0.8)",
                        zIndex: 1,
                      },
                    }}
                  >
                    <CardMedia
                      sx={{ height: 200 }}
                      image={item.img}
                      title="green iguana"
                    />
                    <CardContent>
                      <Typography
                        sx={{ textAlign: "center" }}
                        gutterBottom
                        variant="h6"
                        component="div"
                        color="darkslateblue"
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center", p: 1 }}
                        color="text.secondary"
                        fontWeight={500}
                      >
                        Duration: {item.duration}
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center" }}
                        variant="h5"
                        color="text.secondary"
                      >
                        <CurrencyRupeeIcon />
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          {item.price}{" "}
                        </span>
                        per person
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "center" }}>
                      <ColorButton
                        variant="outlined"
                        component={RouterLink}
                        to={item.path}
                      >
                        View Details
                      </ColorButton>
                      {/* <a href="#" className="btn animated-button thar-three">
                      Register
                    </a> */}
                    </CardActions>
                  </Card>
                </div>
              </Grid>
            ))}
          </Grid>

          <Typography
            variant="h5"
            component="h2"
            sx={{ mt: 3, textAlign: "center", fontWeight: "bold" }}
          >
            Jaipur Temple Tour
          </Typography>
          <Typography
            sx={{
              mt: 1,
              mb: 1,
              textAlign: "center",
              fontWeight: "500",
              color: "#5a6268",
            }}
          >
            Check out these amazing devotional trips
          </Typography>
          <hr className="hrborder" />
          <Box sx={{ flexGrow: 1 }}></Box>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              p: 2,
            }}
          >
            {images.map((image) => (
              <ImageButton
                focusRipple
                key={image.title}
                style={{
                  width: image.width,
                  height: 300,
                }}
              >
                <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
                <ImageBackdrop className="MuiImageBackdrop-root" />
                <Image>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    sx={{
                      position: "relative",
                      p: 4,
                      pt: 2,
                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                    }}
                  >
                    {image.title}
                    <ImageMarked className="MuiImageMarked-root" />
                  </Typography>
                </Image>
              </ImageButton>
            ))}
          </Box>

          <Typography
            variant="h5"
            component="h2"
            sx={{ mt: 3, textAlign: "center", fontWeight: "bold" }}
          >
            Temple Tour Packages
          </Typography>
          <Typography
            sx={{
              mt: 1,
              mb: 1,
              textAlign: "center",
              fontWeight: "500",
              color: "#5a6268",
            }}
          >
            Check out these amazing devotional trips
          </Typography>
          <hr className="hrborder" />
          <Carousel className="single-welcome-slide" fade>
            <Carousel.Item>
              <Grid
                container
                spacing={{ xs: 2, md: 1 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {hotpackages1.map((item, index) => (
                  <Grid item xs={4} sm={3} md={3} key={index}>
                    <Card>
                      <CardMedia
                        sx={{ height: 200 }}
                        image={item.img}
                        title={item.name}
                      />
                      <CardContent>
                        <Typography
                          sx={{ textAlign: "center", color: "darkorange" }}
                          gutterBottom
                          variant="h6"
                          component="div"
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          sx={{ textAlign: "center" }}
                          variant="p"
                          component="div"
                          color="text.secondary"
                        >
                          Duration: {item.duration}
                        </Typography>
                        <Typography
                          sx={{ textAlign: "center" }}
                          variant="h5"
                          color="text.secondary"
                        >
                          <CurrencyRupeeIcon />{" "}
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            {item.price}
                          </span>{" "}
                          per person
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ justifyContent: "center" }}>
                        <ColorButton
                          variant="contained"
                          component={RouterLink}
                          to={item.path}
                        >
                          View Details
                        </ColorButton>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Carousel.Item>

            <Carousel.Item>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {hotpackages2.map((item, index) => (
                  <Grid item xs={4} sm={3} md={3} key={index}>
                    <Card>
                      <CardMedia
                        sx={{ height: 200 }}
                        image={item.img}
                        title={item.name}
                      />
                      <CardContent>
                        <Typography
                          sx={{ textAlign: "center", color: "darkorange" }}
                          gutterBottom
                          variant="h6"
                          component="div"
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          sx={{ textAlign: "center" }}
                          variant="p"
                          component="div"
                          color="text.secondary"
                        >
                          Duration: {item.duration}
                        </Typography>
                        <Typography
                          sx={{ textAlign: "center" }}
                          variant="h5"
                          color="text.secondary"
                        >
                          <CurrencyRupeeIcon />{" "}
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            {item.price}
                          </span>{" "}
                          per person
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ justifyContent: "center" }}>
                        <ColorButton
                          variant="contained"
                          component={RouterLink}
                          to={item.path}
                        >
                          View Details
                        </ColorButton>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Carousel.Item>
          </Carousel>

          <Typography
            variant="h5"
            component="h2"
            sx={{ mt: 3, textAlign: "center", fontWeight: "bold" }}
          >
            Spiritual Packages
          </Typography>
          <Typography
            sx={{
              mt: 1,
              mb: 1,
              textAlign: "center",
              fontWeight: "500",
              color: "#5a6268",
            }}
          >
            Check out these amazing devotional trips
          </Typography>
          <hr className="hrborder" />
          <Carousel fade>
            <Carousel.Item>
              <ImageList sx={{ height: 425, overflow: "hidden" }}>
                {itemData1.map((item) => (
                  <ImageListItem
                    key={item.img}
                    component={RouterLink}
                    to={item.path}
                  >
                    <img
                      src={`${item.img}?w=248&fit=crop&auto=format`}
                      srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.title}
                      loading="lazy"
                    />
                    <ImageListItemBar
                      title={item.title}
                      // subtitle={item.author}
                      actionIcon={
                        <IconButton
                          sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          aria-label={`info about ${item.title}`}
                        >
                          <InfoIcon />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Carousel.Item>
            <Carousel.Item>
              <ImageList sx={{ height: 425, overflow: "hidden" }}>
                {itemData2.map((item) => (
                  <ImageListItem
                    key={item.img}
                    component={RouterLink}
                    to={item.path}
                  >
                    <img
                      src={`${item.img}?w=248&fit=crop&auto=format`}
                      srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.title}
                      loading="lazy"
                    />
                    <ImageListItemBar
                      title={item.title}
                      // subtitle={item.author}
                      actionIcon={
                        <IconButton
                          sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          aria-label={`info about ${item.title}`}
                        >
                          <InfoIcon />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Carousel.Item>
          </Carousel>
        </Container>
        <Typography
          variant="h5"
          component="h2"
          sx={{
            mt: 1,
            textAlign: "center",
            fontWeight: "bold",
            color: "rgba(78, 77, 77, 0.856)",
          }}
        >
          TESTIMONIALS
        </Typography>
        <Container maxWidth="lg">
          <div id="demo" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="carousel-caption-my">
                  <p>
                    "We went on a Chopta tour with Travel Murti. The trip could
                    have been planned in a better way but our trip leader Amit
                    was very helpful and understanding. He tried to help us in
                    the best possible way that he could. He also arranged
                    bonfires at almost all the camps where we stayed. Hotels
                    booked by the team were very nice at every place. We covered
                    almost all the spots on this trip. It was definitely an
                    experience for a lifetime."
                  </p>
                  <img
                    src="https://i.imgur.com/lE89Aey.jpg"
                    alt=""
                    className="img-fluid"
                  />
                  <div id="image-caption">- Navin K</div>
                  <Rating
                    name="text-feedback"
                    value={5}
                    readOnly
                    precision={0.5}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  <Box>{labels[5]}</Box>
                </div>
              </div>
              <div className="carousel-item">
                <div className="carousel-caption-my">
                  <p>
                    "Travel Murti has planned our three dham yatra meticulously
                    as per our time &need. Mr. Amit has taken all his best
                    efforts to provide good service. Journey was safe as the
                    vehicle & driver provided by tour operator were very good.We
                    enjoyed the stay and food as well. Thank you travel murti
                    team..."
                  </p>
                  <img
                    src="https://i.imgur.com/QptVdsp.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div id="image-caption">- Sandeep K</div>
                  <Rating
                    name="text-feedback"
                    value={4.5}
                    readOnly
                    precision={0.5}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  <Box>{labels[4.5]}</Box>
                </div>
              </div>
              <div className="carousel-item">
                <div className="carousel-caption-my">
                  <p>
                    "I am running a travels in Trivandrum, Kerala. We had a very
                    nice dealings with Travel Murti Tours & Travels. I got 5
                    days package from Murti Travels from trivandrum to
                    kanyakumari, rameshwaram, madurai. All places in itenerary
                    was accurate. And it was very good deal for us. All things
                    have been nicely handled by Mr. Amit. Again i expecting
                    future business with Murti Tours & Travels. Thanks for
                    building a good relationship with us 🙏."
                  </p>
                  <img
                    src="https://i.imgur.com/jQWThIn.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div id="image-caption">- Phoenix T</div>
                  <Rating
                    name="text-feedback"
                    value={value}
                    readOnly
                    precision={0.5}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  <Box>{labels[value]}</Box>
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#demo"
              data-slide="prev"
              style={{ padding: "1.5rem" }}
            >
              <span className="css-1l45539">
                <NavigateBeforeIcon id="my-i" />
              </span>
            </a>
            <a className="carousel-control-next" href="#demo" data-slide="next">
              <NavigateNextIcon id="my-i" />
            </a>
          </div>
        </Container>
        <ContactForm />
        <Footer />
      </Box>
    </>
  );
}

const itemData1 = [
  {
    img: "https://www.travelmurti.com/chardhamimages/chardham3.jpg",
    title: "Chardham Tour Package",
    author: "@bkristastucchio",
    rows: 2,
    cols: 2,
    featured: true,
    path: "chardhamtourpackage",
  },
  {
    img: "https://i.pinimg.com/564x/5d/17/e6/5d17e6c198cec40026ad496fb20a8e61.jpg",
    title: "Gujrat Tour Package",
    author: "@rollelflex_graphy726",
    path: "gujrattourpackage",
  },
  {
    img: "https://images.pexels.com/photos/11146084/pexels-photo-11146084.jpeg?auto=compress&cs=tinysrgb&w=600",
    title: "Chardham Tour Package",
    author: "@helloimnik",
    path: "chardhamtourpackage",
  },
  {
    img: "https://images.pexels.com/photos/14388703/pexels-photo-14388703.jpeg?auto=compress&cs=tinysrgb&w=600",
    title: "Gujrat Tour Package",
    author: "@nolanissac",
    cols: 2,
    path: "gujrattourpackage",
  },
];

const itemData2 = [
  {
    img: "https://i.pinimg.com/564x/a7/27/ef/a727ef7991ab2d3f2286742ab78077ef.jpg",
    title: "Himachal Tour Package",
    author: "@bkristastucchio",
    rows: 2,
    cols: 2,
    featured: true,
    path: "himachaltourpackage",
  },
  {
    img: "https://i.pinimg.com/564x/c7/4c/45/c74c45ffb5fcb09133ebc2ee489e0c06.jpg",
    title: "Kashmir Tour Package",
    author: "@rollelflex_graphy726",
    path: "kashmirtourpackage",
  },
  {
    img: "https://images.pexels.com/photos/6113138/pexels-photo-6113138.jpeg?auto=compress&cs=tinysrgb&w=600",
    title: "Himachal Tour Package",
    author: "@helloimnik",
    path: "himachaltourpackage",
  },
  {
    img: "https://images.pexels.com/photos/14838876/pexels-photo-14838876.jpeg?auto=compress&cs=tinysrgb&w=600",
    title: "Kashimar Tour Package",
    author: "@nolanissac",
    cols: 2,
    path: "kashmirtourpackage",
  },
];
