import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import React from "react";
import AuthService from "./services/auth.service";
import { useState } from "react";
import { useCallback } from "react";
import Notification from "../Notification";

export default function ContactForm() {
  const initialUserService = {
    id: null,
    name: "",
    email: "",
    mobile: "",
  };

  const [user, setUser] = React.useState(initialUserService);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [messageColor, setMessageColor] = useState("");

  const handleClose = useCallback((event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const saveUser = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    var data = {
      name: user.name,
      email: user.email,
      mobile: user.mobile,
    };

    AuthService.create(data)
      .then((response) => {
        debugger;
        setUser({
          id: response.data.id,
          fullname: response.data.fullname,
          email: response.data.email,
          mobileno: response.data.mobileno,
        });
        console.log(response.data);
        setLoading(false);
        setOpen(true);
        setMessage("Your are successfully Registerd.");
        setUser(initialUserService);
        setMessageColor("success");
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(e);
        console.log(resMessage);
        setLoading(false);
        setOpen(true);
        setMessage(resMessage);
        setMessageColor("error");
      });
  };
  return (
    <Container>
      <Notification
        message={message}
        messageColor={messageColor}
        isOpen={open}
        onClose={handleClose}
      />
      {/* Start Contact form */}
      <Box
        sx={{
          bgcolor: "#fff",
          borderRadius: "5px",
          flexGrow: 1,
          boxShadow: "0 2px 40px 8px rgb(15 15 15 / 15%)",
          zIndex: 1,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Box
              sx={{
                marginTop: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="p" variant="h4" align="center" padding={2}>
                Contact Form
              </Typography>
              <Typography component="h1" variant="h5">
                Send us a message and we'll get back to you as soon as we can!
              </Typography>
              <Box
                component="form"
                // onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 0.5 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="fullname"
                  label="Full Name"
                  name="name"
                  autoComplete="fullname"
                  size="small"
                  value={user.name}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  size="small"
                  value={user.email}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="mobileno"
                  label="Mobile Number"
                  name="mobile"
                  autoComplete="mobile number"
                  size="small"
                  value={user.mobile}
                  onChange={handleInputChange}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 1 }}
                  onClick={saveUser}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  Send
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
