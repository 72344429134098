import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  CssBaseline,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import React, { useEffect } from "react";
import Footer from "./footer";
import { Link as RouterLink } from "react-router-dom";
import { deepPurple } from "@mui/material/colors";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Helmet } from "react-helmet-async";

const gujaratdata = [
  {
    id: 1,
    img: "https://reactmapmisha.shiftescape.com/images/TravelMurti/gujrat/1/gujrat1.jpg",
    name: "Dwarka Somnath Darshan",
    duration: "2 Nights & 3 Days",
    price: "10,999",
    label: "dwarkasomnathdarshan",
  },
  {
    id: 2,
    img: "https://www.suvidhayatri.com/uploads/29597_29635_Gujarat%20Tour%20Package%20by%20www.suvidhayatri.com%20call%20@%208744992244%20(2).jpg",
    name: "Dwarka Somnath Tour",
    duration: "3 Nights & 4 Days",
    price: "12,999",
    label: "dwarkasomnathtour",
  },
  {
    id: 3,
    img: "https://www.travelmurti.com/gujaratimages/diu1.jpg",
    name: "Gujarat Group Tour",
    duration: "4 Nights & 5 Days",
    price: "15,999",
    label: "gujaratgrouptour",
  },
  {
    id: 4,
    img: "https://www.travelmurti.com/gujaratimages/Ashapura_Mata_Tempe,_Matano_Madh,_Kachchh.jpg",
    name: "Mata No Madh Tour with Koteshwer",
    duration: "4 Nights & 5 Days",
    price: "15,999",
    label: "matanomadhtourwithkoteshwar",
  },
  {
    id: 5,
    img: "https://i.pinimg.com/564x/ce/75/c6/ce75c6c09b3ebf72cfbdb7fb5ad61e4e.jpg",
    name: "Saurashtra Tour Package",
    duration: "6 Nights & 7 Days",
    price: "19,999",
    label: "saurashtratourpackage",
  },
  {
    id: 6,
    img: "https://www.travelmurti.com/gujaratimages/kutch.jpg",
    name: "Gujarat Jain Temple Tour",
    duration: "6 Nights & 7 Days",
    price: "20,999",
    label: "gujaratjaintempletour",
  },
];

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(deepPurple[900]),
  backgroundColor: deepPurple[900],
  "&:hover": {
    backgroundColor: deepPurple[400],
    color: "white",
  },
}));

export default function Gujrat() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Travel Murti | Gujarat Tour</title>
        <meta
          name="description"
          content="With our Gujarat Tour Packages, explore the scenic beauty and
          picturesque landscape of Gujarat. Visit the land of diverse cultures
          and enjoy your vacation to collect lasting impressions, lifelong
          learning, and spellbound memories. The Gujarat holiday packages take
          you to the famous tourist attractions of Ahmedabad, Bhuj, Rajkot,
          Jamnagar, Vadodara, Junagarh, etc."
        ></meta>
      </Helmet>
      <Box className="gujarat-package" sx={{ height: 190 }}>
        <Typography
          component="h3"
          variant="h4"
          align="center"
          color="#fff"
          fontWeight="bold"
          style={{
            padding: "30px 0",
            position: "relative",
            textTransform: "uppercase",
            margin: "0",
          }}
        >
          Gujrat Tour Package
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="#fff"
          component="p"
          fontWeight="bold"
          sx={{
            p: 1,
          }}
        >
          Home <ChevronRightIcon /> Gujrat Tour Package
        </Typography>
      </Box>

      <CssBaseline />
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          component="h2"
          sx={{ p: 3, textAlign: "center", fontWeight: "bold" }}
        >
          Gujrat Tour Package
        </Typography>
        <Typography sx={{ p: 1, textAlign: "center", color: "#636a8c" }}>
          With our Gujarat Tour Packages, explore the scenic beauty and
          picturesque landscape of Gujarat. Visit the land of diverse cultures
          and enjoy your vacation to collect lasting impressions, lifelong
          learning, and spellbound memories. The Gujarat holiday packages take
          you to the famous tourist attractions of Ahmedabad, Bhuj, Rajkot,
          Jamnagar, Vadodara, Junagarh, etc., make the state of Gujarat
          distinctive and thus highly praised in the tourism Industry.
        </Typography>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ mt: 10 }}
          >
            {gujaratdata.map((item, index) => (
              <Grid item xs={4} sm={4} md={4} key={index}>
                <div className="gradient-border">
                  <Card
                    sx={{
                      bgcolor: "#fff",
                      borderRadius: "5px",
                      flexGrow: 1,
                      boxShadow: "0 2px 40px 8px rgb(15 15 15 / 15%)",
                      zIndex: 1,
                    }}
                  >
                    <CardMedia
                      sx={{ height: 200 }}
                      image={item.img}
                      title="green iguana"
                    />
                    <CardContent>
                      <Typography
                        sx={{ textAlign: "center" }}
                        gutterBottom
                        variant="h5"
                        component="div"
                        color="deeppink"
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center" }}
                        variant="h6"
                        color="text.secondary"
                      >
                        Duration: {item.duration}
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center" }}
                        variant="h5"
                        color="text.secondary"
                      >
                        <CurrencyRupeeIcon />{" "}
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          {item.price}
                        </span>{" "}
                        per person
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "center" }}>
                      <ColorButton
                        component={RouterLink}
                        to={`/gujrattourpackage/${item.label}`}
                        variant="contained"
                      >
                        View Details
                      </ColorButton>
                    </CardActions>
                  </Card>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
