import { Outlet } from "react-router-dom";
import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { NavLink as RouterLink } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItemIcon from "@mui/material/ListItemIcon";

import {
  createTheme,
  Fab,
  Fade,
  Grid,
  MenuItem,
  Paper,
  Popover,
  Stack,
  styled,
  ThemeProvider,
  useScrollTrigger,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CallIcon from "@mui/icons-material/Call";
import DraftsIcon from "@mui/icons-material/Drafts";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Home from "@mui/icons-material/Home";
import TempleBuddhistIcon from "@mui/icons-material/TempleBuddhist";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";

const drawerWidth = 247;

const navItems = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "About Us",
    path: "/about",
  },
];

const spiritualtour = [
  {
    title: "Chardham Tour Package",
    path: "/chardhamtourpackage",
  },
  {
    title: "Gujrat Tour Package",
    path: "/gujrattourpackage",
  },
];
const holidaytour = [
  {
    title: "Himanchal Tour Package",
    path: "/himachaltourpackage",
  },
  {
    title: "Kashmir Tour Package",
    path: "/kashmirtourpackage",
  },
];

const contactus = [{ title: "Contact Us", path: "/contactus" }];

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const data = [
  {
    icon: <TempleBuddhistIcon />,
    label: "Chardham Tour",
    path: "/chardhamtourpackage",
  },
  {
    icon: <TempleBuddhistIcon />,
    label: "Gujrat Tour",
    path: "/gujrattourpackage",
  },
];

const navdata = [
  {
    icon: <ModeOfTravelIcon />,
    label: "Himanchal Tour",
    path: "/himachaltourpackage",
  },
  {
    icon: <ModeOfTravelIcon />,
    label: "Kashmir Tour",
    path: "/kashmirtourpackage",
  },
];

const FireNav = styled(List)({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

export default function Root(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [open, setOpen] = React.useState(null);
  const [opens, setOpens] = React.useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleOpens = (event) => {
    setOpens(event.currentTarget);
  };

  const handleCloses = () => {
    setOpens(null);
  };

  const [sidenavopen, setsidenavopen] = React.useState(false);
  const [sidenavopen2, setsidenavopen2] = React.useState(false);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ display: "flex" }}>
      <ThemeProvider
        theme={createTheme({
          components: {
            MuiListItemButton: {
              defaultProps: {
                disableTouchRipple: true,
              },
            },
          },
          palette: {
            mode: "dark",
            primary: { main: "rgb(102, 157, 246)" },
            background: { paper: "rgb(5, 30, 52)" },
          },
        })}
      >
        <Paper elevation={0} sx={{ maxWidth: 256 }}>
          <FireNav component="nav" disablePadding>
            <ListItemButton component="div">
              {/* <ListItemIcon sx={{ fontSize: 20 }}>🔥</ListItemIcon> */}
              <ListItemText
                sx={{ my: 0 }}
                primary="Travel Murti"
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: "medium",
                  letterSpacing: 0,
                }}
              />
            </ListItemButton>
            <Divider />
            <ListItem component="div" disablePadding>
              <ListItemButton
                sx={{ height: 56 }}
                component={RouterLink}
                to={"/"}
              >
                <ListItemIcon>
                  <Home color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Home"
                  primaryTypographyProps={{
                    color: "primary",
                    fontWeight: "medium",
                    variant: "body2",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <Divider />
            <Box
              sx={{
                bgcolor: sidenavopen ? "rgba(71, 98, 130, 0.2)" : null,
                pb: sidenavopen ? 2 : 0,
              }}
              onClick={handleDrawerToggle}
            >
              <ListItemButton
                alignItems="flex-start"
                onClick={() => setsidenavopen(!sidenavopen)}
                sx={{
                  px: 3,
                  pt: 1.5,
                  pb: sidenavopen ? 0 : 1.5,
                  "&:hover, &:focus": {
                    "& svg": { opacity: sidenavopen ? 1 : 1 },
                  },
                }}
              >
                <ListItemText
                  primary="Spiritual Tour"
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: "medium",
                    lineHeight: "20px",
                    mb: "2px",
                  }}
                  secondary="Chardham Tour, Gujrat Tour, Jagannath Puri"
                  secondaryTypographyProps={{
                    noWrap: true,
                    fontSize: 12,
                    lineHeight: "16px",
                    color: sidenavopen
                      ? "rgba(0,0,0,0)"
                      : "rgba(255,255,255,0.5)",
                  }}
                  sx={{ my: 0 }}
                />
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    opacity: 1,
                    transform: sidenavopen ? "rotate(-180deg)" : "rotate(0)",
                    transition: "0.2s",
                  }}
                />
              </ListItemButton>
              {sidenavopen &&
                data.map((item) => (
                  <ListItemButton
                    key={item.label}
                    sx={{ py: 0, minHeight: 40, color: "rgba(255,255,255,.8)" }}
                    component={RouterLink}
                    to={item.path}
                    className="nav-link"
                    onClick={handleDrawerToggle}
                  >
                    <ListItemIcon sx={{ color: "inherit" }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.label}
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontWeight: "medium",
                      }}
                    />
                  </ListItemButton>
                ))}
            </Box>
            <Divider />
            <Box
              sx={{
                bgcolor: sidenavopen2 ? "rgba(71, 98, 130, 0.2)" : null,
                pb: sidenavopen2 ? 2 : 0,
              }}
              onClick={handleDrawerToggle}
            >
              <ListItemButton
                alignItems="flex-start"
                onClick={() => setsidenavopen2(!sidenavopen2)}
                sx={{
                  px: 3,
                  pt: 1.5,
                  pb: sidenavopen2 ? 0 : 1.5,
                  "&:hover, &:focus": {
                    "& svg": { opacity: sidenavopen2 ? 1 : 1 },
                  },
                }}
              >
                <ListItemText
                  primary="Holiday Tour"
                  primaryTypographyProps={{
                    fontSize: 15,
                    fontWeight: "medium",
                    lineHeight: "20px",
                    mb: "2px",
                  }}
                  secondary="Himanchal Tour, Kashmir Tour, Goa Tour"
                  secondaryTypographyProps={{
                    noWrap: true,
                    fontSize: 12,
                    lineHeight: "16px",
                    color: sidenavopen2
                      ? "rgba(0,0,0,0)"
                      : "rgba(255,255,255,0.5)",
                  }}
                  sx={{ my: 0 }}
                />
                <KeyboardArrowDown
                  sx={{
                    mr: -1,
                    opacity: 1,
                    transform: sidenavopen2 ? "rotate(-180deg)" : "rotate(0)",
                    transition: "0.2s",
                  }}
                />
              </ListItemButton>
              {sidenavopen2 &&
                navdata.map((item) => (
                  <ListItemButton
                    key={item.label}
                    sx={{ py: 0, minHeight: 40, color: "rgba(255,255,255,.8)" }}
                    component={RouterLink}
                    to={item.path}
                    className="nav-link"
                    onClick={handleDrawerToggle}
                  >
                    <ListItemIcon sx={{ color: "inherit" }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.label}
                      primaryTypographyProps={{
                        fontSize: 14,
                        fontWeight: "medium",
                      }}
                    />
                  </ListItemButton>
                ))}
            </Box>
            <Divider />
            <ListItem component="div" disablePadding>
              <ListItemButton
                sx={{ height: 56 }}
                component={RouterLink}
                to={"/about"}
                className="nav-link"
              >
                {/* <ListItemIcon>
                  <Home color="primary" />
                </ListItemIcon> */}
                <ListItemText
                  primary="About Us"
                  primaryTypographyProps={{
                    fontWeight: "medium",
                    variant: "body2",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem component="div" disablePadding>
              <ListItemButton
                sx={{ height: 56 }}
                component={RouterLink}
                to={"/contactus"}
                className="nav-link"
              >
                {/* <ListItemIcon>
                  <Home color="primary" />
                </ListItemIcon> */}
                <ListItemText
                  primary="Contact Us"
                  primaryTypographyProps={{
                    fontWeight: "medium",
                    variant: "body2",
                  }}
                />
              </ListItemButton>
            </ListItem>
          </FireNav>
        </Paper>
      </ThemeProvider>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        component="nav"
        style={{
          background: "linear-gradient(to right, #101c81, #2a6ba3)",
        }}
      >
        <div style={{ backgroundColor: "darkblue" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <CallIcon
                  style={{
                    color: "#fff",
                    // display: "inline-block",
                  }}
                />
                <a href="tel:8527036496" style={{ textDecoration: "none" }}>
                  <span
                    style={{
                      color: "white",
                      marginLeft: "2px",
                      fontWeight: "bold",
                    }}
                  >
                    +91 85-270-36496
                  </span>{" "}
                </a>
                <DraftsIcon
                  style={{
                    color: "#fff",
                    // display: "inline-block",
                  }}
                />
                <a
                  href="mailto:contact@travelmurti.com"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      color: "white",
                      marginLeft: "2px",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  >
                    contact@travelmurti.com
                  </span>
                </a>
              </div>
              {/* <div className="col-md-6" style={{ marginTop: "5px" }}>
                <div className="top-header-content">
                  <div className="top-social-area ml-auto">
                    <a href="/">
                      <i
                        className="fa fa-facebook"
                        aria-hidden="true"
                        style={{ color: "white", paddingLeft: "20px" }}
                      ></i>
                    </a>
                    <a href="/">
                      <i
                        className="fa fa-twitter"
                        aria-hidden="true"
                        style={{ color: "white", paddingLeft: "20px" }}
                      ></i>
                    </a>
                    <a href="/">
                      <i
                        className="fa fa-tripadvisor"
                        aria-hidden="true"
                        style={{ color: "white", paddingLeft: "20px" }}
                      ></i>
                    </a>
                    <a href="/">
                      <i
                        className="fa fa-instagram"
                        aria-hidden="true"
                        style={{ color: "white", paddingLeft: "20px" }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <img
            height={50}
            alt="Logo"
            src="https://lh4.googleusercontent.com/-43TdC72iuWI/AAAAAAAAAAI/AAAAAAAAAAA/vLm5URYYrSY/s44-p-k-no-ns-nd/photo.jpg"
          />
          <Typography
            variant="h6"
            //component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block" },
              ml: 1,
            }}
            component={RouterLink}
            to={"/"}
            className="nav-link"
          >
            Travel Murti
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item, index) => (
              <Button
                key={index}
                sx={{ color: "#fff" }}
                component={RouterLink}
                to={item.path}
                className="nav-link"
              >
                {item.title}
              </Button>
            ))}
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button onClick={handleOpen} sx={{ color: "white" }}>
              Sepurtal Tour <ExpandMoreIcon />
            </Button>
            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                sx: {
                  p: 1,
                  mt: 1.5,
                  ml: 0.75,
                  width: 210,
                  "& .MuiMenuItem-root": {
                    p: 1,
                    borderRadius: 2,
                  },
                },
              }}
            >
              <Stack spacing={0.75}>
                {spiritualtour.map((option) => (
                  <MenuItem
                    key={option.title}
                    onClick={() => handleClose()}
                    component={RouterLink}
                    to={option.path}
                    className="nav-link"
                    sx={{
                      color: "indigo",
                      justifyContent: "center",
                      "&:hover": {
                        backgroundColor: "indigo",
                        color: "white",
                      },
                    }}
                  >
                    {option.title}
                  </MenuItem>
                ))}
              </Stack>
            </Popover>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button onClick={handleOpens} sx={{ color: "white" }}>
              Holiday Tour <ExpandMoreIcon />
            </Button>
            <Popover
              open={Boolean(opens)}
              anchorEl={opens}
              onClose={handleCloses}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                sx: {
                  p: 1,
                  mt: 1.5,
                  ml: 0.75,
                  width: 210,
                  "& .MuiMenuItem-root": {
                    p: 1,
                    borderRadius: 2,
                  },
                },
              }}
            >
              <Stack spacing={0.75}>
                {holidaytour.map((option) => (
                  <MenuItem
                    key={option.title}
                    onClick={() => handleCloses()}
                    component={RouterLink}
                    to={option.path}
                    className="nav-link"
                    sx={{
                      color: "indigo",
                      justifyContent: "center",
                      "&:hover": {
                        backgroundColor: "indigo",
                        color: "white",
                      },
                    }}
                  >
                    {option.title}
                  </MenuItem>
                ))}
              </Stack>
            </Popover>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {contactus.map((item, index) => (
              <Button
                key={index}
                sx={{ color: "#fff" }}
                component={RouterLink}
                to={item.path}
                className="nav-link"
              >
                {item.title}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "rgb(5, 30, 52)",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          "& .markdown": {
            py: 3,
          },
        }}
      >
        <Toolbar id="back-to-top-anchor" style={{ marginTop: "15px" }} />
        <Outlet />
      </Grid>
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}

Root.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
