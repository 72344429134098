import { Box, Container, CssBaseline, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Footer from "./footer";
import CallIcon from "@mui/icons-material/Call";
import HomeIcon from "@mui/icons-material/Home";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DraftsIcon from "@mui/icons-material/Drafts";
import { Helmet } from "react-helmet-async";
import ContactForm from "./Contact";

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Travel Murti | Contact Us</title>
        <meta
          name="description"
          content="4th Floor, Kamal Bhati Market, Sector-86, Yakubpur, Noida,
          Utter Pradesh, 201305"
        ></meta>
      </Helmet>
      <Box className="background-image" sx={{ height: 175 }}>
        <Typography
          component="h1"
          variant="h3"
          align="center"
          color="#fff"
          fontWeight={400}
          style={{
            padding: "40px 0",
            position: "relative",
            textTransform: "uppercase",
            margin: "0",
          }}
        >
          Contact Us
        </Typography>
      </Box>
      <CssBaseline />
      <Container maxWidth="lg">
        <Box
          sx={{
            bgcolor: "#fff",
            borderRadius: "5px",
            flexGrow: 1,
            boxShadow: "0 2px 40px 8px rgb(15 15 15 / 15%)",
            zIndex: 1,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <Box sx={{ textAlign: "center", mt: 2 }}>
                <CallIcon
                  style={{
                    color: "#1cc3b2",
                    fontSize: "30px",
                    display: "inline-block",
                  }}
                />
                <Typography
                  component="p"
                  variant="h4"
                  align="center"
                  padding={2}
                >
                  Phone
                </Typography>
                <Typography
                  component="p"
                  align="center"
                  padding={2}
                  color="#636a76"
                  fontSize="16px"
                  fontWeight={400}
                >
                  <a
                    href="tel:8527036496"
                    style={{ textDecoration: "none", color: "#636a76" }}
                  >
                    +91-8527036496
                  </a>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Box sx={{ textAlign: "center", mt: 2 }}>
                <HomeIcon
                  style={{
                    color: "#1cc3b2",
                    fontSize: "30px",
                    display: "inline-block",
                  }}
                />
                <Typography component="p" variant="h4" align="center">
                  Address
                </Typography>
                <Typography
                  component="p"
                  align="center"
                  padding={2}
                  color="#636a76"
                  fontSize="16px"
                  fontWeight={400}
                >
                  4th Floor, Kamal Bhati Market, Sector-86, Yakubpur, Noida,
                  Utter Pradesh, 201305
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Box sx={{ textAlign: "center", mt: 2 }}>
                <AccessTimeIcon
                  style={{
                    color: "#1cc3b2",
                    fontSize: "30px",
                    display: "inline-block",
                  }}
                />
                <Typography
                  component="p"
                  variant="h4"
                  align="center"
                  padding={2}
                >
                  Open Time
                </Typography>
                <Typography
                  component="p"
                  align="center"
                  padding={2}
                  color="#636a76"
                  fontSize="16px"
                  fontWeight={400}
                >
                  10:00 am to 06:00 pm
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Box sx={{ textAlign: "center", mt: 2 }}>
                <DraftsIcon
                  style={{
                    color: "#1cc3b2",
                    fontSize: "30px",
                    display: "inline-block",
                  }}
                />
                <Typography
                  component="p"
                  variant="h4"
                  align="center"
                  padding={2}
                >
                  Email
                </Typography>
                <Typography
                  component="p"
                  align="center"
                  padding={2}
                  color="#636a76"
                  fontSize="16px"
                  fontWeight={400}
                >
                  <a
                    href="mailto:contact@travelmurti.com"
                    style={{ textDecoration: "none", color: "#636a76" }}
                  >
                    contact@travelmurti.com
                  </a>
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3505.5001446842443!2d77.402638!3d28.524683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDMxJzI4LjkiTiA3N8KwMjQnMDkuNSJF!5e0!3m2!1sen!2sin!4v1679840495650!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="Location"
          ></iframe>

          {/* <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={3}>
              <Box sx={{ textAlign: "center", mt: 2 }}>
                <HomeIcon
                  style={{
                    color: "#1cc3b2",
                    fontSize: "30px",
                    display: "inline-block",
                  }}
                />
                <Typography
                  component="p"
                  variant="h4"
                  align="center"
                  padding={2}
                >
                  Address
                </Typography>
                <Typography
                  component="p"
                  align="center"
                  padding={2}
                  color="#636a76"
                  fontSize="16px"
                  fontWeight={400}
                >
                  B-42, 2nd Floor, Sector-64, Noida-201301 Noida
                </Typography>
              </Box>
            </Grid>
          </Grid> */}
        </Box>
        <ContactForm />
      </Container>
      <Footer />
    </React.Fragment>
  );
}
