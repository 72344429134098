import http from "../services/http-common";

const create = (data) => {
  console.log(data);
  return http.post("api/user", data);
};

const getAll = () => {
  return http.get("api/user");
};

const AuthService = {
  create,
  getAll,
};

export default AuthService;
