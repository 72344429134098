import { Link, Container, Typography, Grid, Divider } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: "white" }}>
      {"Copyright © "}
      <Link
        color="inherit"
        fontWeight={500}
        href="https://travelmurti.com/"
        sx={{
          "&:hover": {
            color: "#fff",
          },
        }}
      >
        Travel Murti
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      maxWidth="xl"
      component="footer"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        py: [3, 6],
        backgroundColor: "#0e2737",
      }}
    >
      <Grid container spacing={4} justifyContent="space-evenly">
        {footers.map((footer) => (
          <Grid item xs={12} sm={3} key={footer.id}>
            <Typography variant="h6" color="#fff" gutterBottom>
              {footer.title}
            </Typography>
            <Divider className="hrborderfooter" />
            <ul>
              {footer.description.map((item, index) => (
                <li key={index} style={{ color: "white" }}>
                  <Link
                    className="nav-link"
                    variant="subtitle1"
                    color="#fff"
                    style={{ textDecoration: "none" }}
                    component={RouterLink}
                    to={item.path}
                  >
                    <Typography variant="h6" color="#fff" gutterBottom>
                      {footer.company}
                    </Typography>
                    {item.title}
                    <Typography color="#fff" gutterBottom>
                      {footer.icon}
                      {"  "}
                      <a
                        href="tel:8527036496"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        {footer.phone}
                      </a>
                    </Typography>
                    <Typography color="#fff" gutterBottom>
                      {footer.icon2}
                      {"  "}
                      <a
                        href="mailto:contact@travelmurti.com"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        {footer.email}
                      </a>
                    </Typography>
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
        ))}
      </Grid>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}

const footers = [
  {
    id: 1,
    title: "Quick Links",
    description: [
      {
        title: "Home",
        path: "/",
      },
      {
        title: "About US",
        path: "/about",
      },
      {
        title: "Contact US",
        path: "/contactus",
      },
      {
        title: "Location",
        path: "https://goo.gl/maps/mKrC88GWNjKhTbMV9",
      },
    ],
  },
  {
    id: 2,
    title: "Features",
    description: [
      {
        title: "Chardham Tour",
        path: "/chardhamtourpackage",
      },
      {
        title: "Gujrat Tour",
        path: "/gujrattourpackage",
      },
      {
        title: "Himachal Tour",
        path: "/himachaltourpackage",
      },
      {
        title: "Kashmir Tour",
        path: "/kashmirtourpackage",
      },
    ],
  },
  {
    id: 3,
    title: "Contact Us",
    company: "Travel Murti",
    icon: <LocalPhoneIcon />,
    phone: "8527036496",
    email: "contact@travelmurti.com",
    icon2: <EmailIcon />,
    description: [
      {
        title:
          "4th Floor, Kamal Bhati Market, Sector-86, Yakubpur, Noida, Utter Pradesh, 201305",
      },
    ],
  },
];
